/* global fbq */
import { useState, useEffect } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import logo from "../assets/img/advetrees_logo.png";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    setMessage(t('footer.subscribeButton'));
  }, [t, i18n.language]);

  const trackLead = () => { // Define the trackLead function
    fbq('track', 'Lead');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = {
        email: email
      };

      const response = await fetch('https://mlek9h42g5.execute-api.eu-north-1.amazonaws.com/proxy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setEmail('');
        setMessage(t('footer.subscribedMessage')); // Update button text to "Subscribed!"
        setError('');
        trackLead();  // Track the lead when the form submission is successful
      } else {
        setError(t('footer.errorMessage')); // Set custom error message
      }
    } catch (error) {
      setError(t('footer.errorMessage'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4}>
            <img src={logo} alt="Advetrees Logo" style={{ maxWidth: '150px', marginBottom: '20px' }} />
            <Form.Label>
              <Trans i18nKey="footer.promotionPart1">
              </Trans>
              <span style={{ color: '#c4ea2af3' }}>
                <Trans i18nKey="footer.promotionPart2">
                </Trans>
              </span>
            </Form.Label>
            <Form onSubmit={handleSubmit} className="footer-form">
              <div className="input-group">
                <Form.Control
                  type="email"
                  placeholder={t('footer.emailPlaceholder')}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <Button variant="primary" type="submit" disabled={loading}>
                  {message} {/* Display dynamic button text */}
                </Button>
              </div>
              <div className="terms">
                {t('footer.terms')}
              </div>
            </Form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </Col>
          <Col className="nav-foll-col" md={4}>
            <h5>{t('footer.navigation')}</h5>
            <ul className="navigation-list">
              {['home', 'vision', 'approach', 'process', 'ecoInitiative'].map(item => (
                <li key={item}><a href={`#${item}`}>{t(`navbar.${item}`)}</a></li>
              ))}
            </ul>
          </Col>
          <Col className="nav-foll-col" md={4}>
          <h5>{t('footer.followUs')}</h5>
            <ul className="follow-list">
              <li><a href="https://twitter.com/advetrees">𝕏</a></li>
              <li><a href="https://www.linkedin.com/company/advetrees/">LinkedIn</a></li>
              <li><a href="https://www.facebook.com/advetrees/">Facebook</a></li>
              <li><a href="https://www.instagram.com/advetrees/">Instagram</a></li>
              <li><a href="https://medium.com/@advetrees">Medium</a></li>
            </ul>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col md={6}>
            <h6 className="address-title">{t('footer.addressTitle')}</h6>
            <p className="address-detail">{t('footer.addressDetail')}</p>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <p className="mb-0 copyright-text">{t('footer.copyright')}</p>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={6}>
            <h6 className="address-title">{t('footer.inquiryTitle')}</h6>
            <p><a href="mailto:info@advetrees.com" className="email-link">info@advetrees.com</a></p>
            <p className="phone-number"><a href="tel:+31647859675">+31 647859675</a></p> {/* Added clickable phone number */}
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={6}></Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            <a href="/privacy-policy" className="ml-3 privacy-policy">{t('footer.privacyPolicy')}</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
