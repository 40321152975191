import React, { useEffect, useRef, useState } from 'react';
import './TextPopup.css'; // Make sure your CSS is linked correctly

const TextPopup = ({ headerHtml, subTextHtml }) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                rootMargin: '0px',
                threshold: 0.75 // Adjust this to control when the effect triggers
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if(ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref]); // Dependency array ensures effect runs only once

    return (
        <div ref={ref} className={`text-popup ${isVisible ? 'scale-up' : 'scale-down'}`}>
            <h1 dangerouslySetInnerHTML={{ __html: headerHtml }}></h1>
            <h5 dangerouslySetInnerHTML={{ __html: subTextHtml }}></h5>
        </div>
    );
}

export default TextPopup;
