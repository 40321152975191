    import React, { useState, useEffect } from 'react';
    import { useTranslation } from 'react-i18next';
    import CustomButton from './CustomButton';
    import TextPopup from './TextPopup';
    import aiImgEN from '../assets/img/ai_features.png'; // English Images
    import aiImgNL from '../assets/img/ai_features_nl.png'; // Dutch Image
    import SectionHeadingCapsule from './SectionHeadingCapsule';
    import { Container, Row, Col } from "react-bootstrap";

    export const Approach = () => {
        const { t, i18n } = useTranslation();
        const [isVisible, setVisible] = useState(false);
        const aiImg = i18n.language === 'nl' ? aiImgNL : aiImgEN; // Dynamically select image based on language

        const handleScroll = () => {
            const section = document.getElementById('approach');
            const screenPosition = window.innerHeight;
            const sectionTop = section.getBoundingClientRect().top;

            if (sectionTop < screenPosition) {
                setVisible(true);
            } else {
                setVisible(false);  // Optionally hide again when out of view
            }
        };

        useEffect(() => {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

        return (
            <section className="approach-section common-background" id="approach">
                <div className="container">
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <SectionHeadingCapsule title={t('approach.ourApproach')} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={12} md={8}>
                            <div className="content">
                                {isVisible && (
                                    <>
                                        <TextPopup
                                            headerHtml={t('approach.header')}
                                            className="fadeInUp" // Define this animation in your CSS
                                        />
                                        <img src={aiImg} alt={t('approach.altText')} className="img-fluid approach-img fadeInUp" />
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        );
    }
    
    export default Approach;