import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Vision } from './components/Vision';
import { Approach } from './components/Approach';
import { Process } from './components/Process'
import { EcoInitiative } from './components/EcoInitiative';
import MetaAdsLanding from './components/MetaAdsLanding';
import ThankYouPage from './components/ThankYouPage';
import Globe from './components/Globe'; 
import {WhyChooseUs} from './components/WhyChooseUs';
import { Skills } from './components/Skills';
import { Projects } from './components/Projects';
import { Footer } from './components/Footer';
import CalendlyWidget from './components/CalendlyWidget';
import 'bootstrap/dist/css/bootstrap.min.css'
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<>
            <NavBar />
            <Banner />
            <Vision />
            <Approach />
            <Process />
            <EcoInitiative />
            <Footer />
          </>} />
          <Route path="/free-meta-ads-guide" element={<MetaAdsLanding />} />
          <Route path="/free-strategy-call" element={<ThankYouPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;