import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame, useLoader, extend } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei';
import { TextureLoader, MeshBasicMaterial, SphereGeometry, Mesh } from 'three';

extend({ OBJLoader });

const latLongToVector3 = (lat, lon, radius) => {
  const phi = (90 - lat) * (Math.PI / 180);
  const theta = (lon + 180) * (Math.PI / 180);
  return [
    -(radius * Math.sin(phi) * Math.cos(theta)),
    radius * Math.cos(phi),
    radius * Math.sin(phi) * Math.sin(theta),
  ];
};

const TreeModel = ({ lat, lon, modelPath, onClick, partner }) => {
  const obj = useLoader(OBJLoader, modelPath);
  const position = latLongToVector3(lat, lon, 1.92); // Adjusted to ensure it touches the surface
  const greenMaterial = new THREE.MeshBasicMaterial({ color: 'green' }); // Define the green material

  // Use useRef for the object's transformation and material update
  const objRef = useRef();
  useEffect(() => {
    const normal = new THREE.Vector3(...position).normalize();
    const up = new THREE.Vector3(0, 1, 0);
    const quaternion = new THREE.Quaternion().setFromUnitVectors(up, normal);
    if (objRef.current) {
      objRef.current.quaternion.copy(quaternion);
      // Apply the green material to all mesh children
      objRef.current.traverse((child) => {
        if (child.isMesh) {
          child.material = greenMaterial;
        }
      });
    }
  }, [position, greenMaterial]);

  return (
    <primitive
      ref={objRef}
      object={obj}
      position={position}
      scale={[0.02, 0.02, 0.02]} // Adjust scale as needed
      onClick={() => onClick(partner)}
    />
  );
};

const EarthAndTrees = () => {
  
  const earthRef = useRef();
  const texture = new TextureLoader().load(`${process.env.PUBLIC_URL}/assets/earth_texture.jpg`);

  const earthMaterial = new MeshBasicMaterial({
    map: texture,
    color: 0xdedcdc // Using white to keep the texture's natural colors
  });

  // Rotate the Earth
  useFrame(() => {
    if (earthRef.current) {
      earthRef.current.rotation.y += 0.0009; // Adjust this value to control the speed of rotation
    }
  });

  return (
    <mesh ref={earthRef} geometry={new SphereGeometry(2, 64, 64)} material={earthMaterial}> {/* Adjusted scale to 2 */}
      {[
        { lat: 34.0522, lon: -118.2437, partner: 'Partner A', modelPath: '/models/conifer_macedonian_pine.obj' },
        // Add more tree data here
      ].map((tree, index) => (
        <TreeModel key={index} {...tree} onClick={() => alert(`This tree was planted in collaboration with ${tree.partner}`)} />
      ))}
    </mesh>
  );
};

const Globe = ({ height }) => {
  return (
    <div style={{ height: '80vh', backgroundColor: 'rgba(3, 14, 1, 0.356)' }}> {/* Set the background color to white */}
      <Canvas camera={{ position: [0, 0.3, 2.5], fov: 75 }}>
        <EarthAndTrees />
        <OrbitControls enableZoom={false} minDistance={4.5} maxDistance={5} />
      </Canvas>
    </div>
  );
};

export default Globe;
