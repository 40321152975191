import React from 'react';
import MetaAdsNavbar from './MetaAdsNavbar';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaAdsNavbar />
      <div className="privacy-policy-container">
        <h1>{t('privacyPolicy.title')}</h1>
        <p className="last-updated">{t('privacyPolicy.lastUpdated')}</p>

        <h2>{t('privacyPolicy.introduction')}</h2>
        <p>{t('privacyPolicy.introductionText')}</p>

        <h2>{t('privacyPolicy.infoWeCollect')}</h2>
        <p>{t('privacyPolicy.infoWeCollectText')}</p>
        <ul>
          <li>{t('privacyPolicy.personalInfo')}</li>
          <li>{t('privacyPolicy.technicalData')}</li>
          <li>{t('privacyPolicy.usageData')}</li>
          <li>{t('privacyPolicy.marketingData')}</li>
        </ul>

        <h2>{t('privacyPolicy.howWeUse')}</h2>
        <p>{t('privacyPolicy.howWeUseText')}</p>
        <ul>
          <li>{t('privacyPolicy.provisionServices')}</li>
          <li>{t('privacyPolicy.improveServices')}</li>
          <li>{t('privacyPolicy.marketing')}</li>
          <li>{t('privacyPolicy.legalCompliance')}</li>
        </ul>

        <h2>{t('privacyPolicy.legalBasis')}</h2>
        <p>{t('privacyPolicy.legalBasisText')}</p>
        <ul>
          <li>{t('privacyPolicy.performanceContract')}</li>
          <li>{t('privacyPolicy.legitimateInterests')}</li>
          <li>{t('privacyPolicy.consent')}</li>
          <li>{t('privacyPolicy.legalObligation')}</li>
        </ul>

        <h2>{t('privacyPolicy.sharingInfo')}</h2>
        <p>{t('privacyPolicy.sharingInfoText')}</p>
        <ul>
          <li>{t('privacyPolicy.serviceProviders')}</li>
          <li>{t('privacyPolicy.professionalAdvisers')}</li>
          <li>{t('privacyPolicy.complianceLaws')}</li>
          <li>{t('privacyPolicy.businessTransfers')}</li>
        </ul>

        <h2>{t('privacyPolicy.dataSecurity')}</h2>
        <p>{t('privacyPolicy.dataSecurityText')}</p>
        <ul>
          <li>{t('privacyPolicy.encryption')}</li>
          <li>{t('privacyPolicy.regularAudits')}</li>
          <li>{t('privacyPolicy.accessControls')}</li>
        </ul>

        <h2>{t('privacyPolicy.dataRetention')}</h2>
        <p>{t('privacyPolicy.dataRetentionText')}</p>

        <h2>{t('privacyPolicy.yourRights')}</h2>
        <p>{t('privacyPolicy.yourRightsText')}</p>
        <ul>
          <li>{t('privacyPolicy.access')}</li>
          <li>{t('privacyPolicy.rectification')}</li>
          <li>{t('privacyPolicy.erasure')}</li>
          <li>{t('privacyPolicy.restriction')}</li>
          <li>{t('privacyPolicy.dataPortability')}</li>
          <li>{t('privacyPolicy.objection')}</li>
          <li>{t('privacyPolicy.withdrawConsent')}</li>
        </ul>
        <p>{t('privacyPolicy.contactUsText')} <a href={`mailto:${t('privacyPolicy.email')}`}>{t('privacyPolicy.email')}</a>.</p>

        <h2>{t('privacyPolicy.cookies')}</h2>
        <p>{t('privacyPolicy.cookiesText')}</p>

        <h2>{t('privacyPolicy.thirdPartyLinks')}</h2>
        <p>{t('privacyPolicy.thirdPartyLinksText')}</p>

        <h2>{t('privacyPolicy.childrensPrivacy')}</h2>
        <p>{t('privacyPolicy.childrensPrivacyText')}</p>

        <h2>{t('privacyPolicy.changesPolicy')}</h2>
        <p>{t('privacyPolicy.changesPolicyText')}</p>

        <h2>{t('privacyPolicy.contactUs')}</h2>
        <p>{t('privacyPolicy.contactUsText')}</p>
        <p><a href={`mailto:${t('privacyPolicy.email')}`}>{t('privacyPolicy.email')}</a><br />
        {t('privacyPolicy.address')}</p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
