import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import MetaAdsNavbar from './ThankYouNavbar';
import CalendlyWidget from './CalendlyWidget';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ThankYouPage = () => {
  const { i18n, t } = useTranslation();
  const query = useQuery();
  const lang = query.get('lang');

  useEffect(() => {
    if (lang) {
        i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  return (
    <>
      <MetaAdsNavbar />
      <section className="thank-you-section" id="thank-you">
        <Container>
          <Row className="justify-content-center align-items-center text-center">
            <Col lg={8} md={10} sm={12}>
              <h1 className="thank-you-title">{t('thankYou.title')}</h1>
              <p className="thank-you-text">
                {t('thankYou.subText')}
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={10} md={12} sm={12}>
              <CalendlyWidget />  {/* Embed CalendlyWidget here */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ThankYouPage;
