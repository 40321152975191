import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Initialize i18next with hardcoded translations
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "navbar.home": "Home",
          "navbar.vision": "Vision",
          "navbar.approach": "Approach",
          "navbar.ecoInitiative": "Eco-Initiative",
          "navbar.process": "Process",
          "navbar.letsConnect": "Let's Connect",
          "navbar.bookACall": "Press <span class='shortcut-key'>B</span> to book a call",
          "Sales": "Sales",
          "Conversions": "Conversions",
          "Leads": "Leads",
          "Profits": "Profits 💸",
          "Clicks": "Clicks",
          "ROI": "ROI",
          "marketing.boost": "Sustainable Marketing to Boost",
          "clicks.trees": "Turning Clicks into Trees - Grow Your Brand and the Planet 🌍",
          "strategy.call": "Get a FREE Strategy Call",
          "scroll.down": "Scroll down...",
          "vision.ourVision": "Our Vision",
          "vision.header": "Tailored Strategies, <span class='highlight'>Guaranteed</span> Results",
          "vision.subText": "We are a digital marketing agency based in Amsterdam, crafting streamlined strategies for local businesses. Unlike larger agencies, where clients can get lost in the shuffle, we ensure personalized service with direct, transparent communication.",
          "vision.bookCall": "Book Your Call Now",
          "vision.scroll": "Scroll down...",
          "approach.ourApproach": "Our Approach",
          "approach.header": "<span class='highlight'>AI-Powered</span> Campaign Optimization",
          "approach.altText": "Brain",
          "process.ourProcess": "Our Process",
          "process.header": "Creating <span class='highlight'>Success</span> Together",
          "process.bookCall": "Book Your Call Now",
          "process.scroll": "Scroll down...",
          "ecoInitiative.sectionTitle": "Eco-Initiative",
          "ecoInitiative.header": "Join us in Growing a <span class='highlight'>Greener Tomorrow</span>",
          "ecoInitiative.subText": "Every click in our campaigns plants trees in crucial ecological areas, offsetting carbon emissions from digital ads. We provide transparent reports on each tree's impact. Let's nurture the planet together, one click at a time!",
          "footer.promotionPart1": "4 Steps to Getting More Clients with Meta Ads... ",
          "footer.promotionPart2": "(For Free)",
          'footer.subscribeButton': "Claim it Now",
          'footer.subscribedMessage': "Subscribed!",
          'footer.errorMessage': "Please try again later.",
          'footer.emailPlaceholder': "Enter your email",
          'footer.terms': "By clicking Sign Up you're confirming that you agree with our Terms and Conditions.",
          'footer.logoAltText': "Advetrees Logo",
          'footer.navigation': "Navigation",
          'footer.followUs': "Follow us",
          'footer.addressTitle': "Address",
          'footer.addressDetail': "Kingsfordweg 151, Amsterdam, 1043 GR, Netherlands",
          'footer.inquiryTitle': "Inquiry",
          'footer.email': "info@advetrees.com",
          'footer.privacyPolicy': "Privacy Policy",
          'footer.copyright': "© 2024 Advetrees Marketing. All rights reserved.",
          "metaAdsLanding.title": "Want to Skyrocket Your Business with Meta Ads?",
          "metaAdsLanding.getGuide": "Get your free guide now and start boosting your business today!",
          "metaAdsLanding.boostBusiness": "Boost your business with Meta Ads!",
          "metaAdsLanding.subscribeButton": "Claim it Now",
          "metaAdsLanding.readerCount": "Join 100+ readers",
          'metaAdsLanding.emailPlaceholder': "Enter your email",
          'metaAdsLanding.subscribedMessage': "Subscribed!",
          'metaAdsLanding.errorMessage': "Please try again later.",
          "thankYou.title": "Thank You!",
          "thankYou.subText": "Your free guide is on its way and should arrive in your mailbox within a few minutes. If you don't see it, please check your SPAM folder.",
          "calendly.title": "Interested in a Free Marketing Analysis?",
          "calendly.description": "Discover what we can do for your business with no obligations and no high-pressure sales tactics. Select a time in the calendar to chat with us. We value your time and commitment to growth.",
          "privacyPolicy.title": "Privacy Policy",
          "privacyPolicy.lastUpdated": "Last Updated: [May 25 2024]",
          "privacyPolicy.introduction": "1. Introduction",
          "privacyPolicy.introductionText": "Welcome to Advetrees Marketing (“we”, “our”, “us”). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website [www.advetrees.com], use our services, or otherwise interact with us.",
          "privacyPolicy.infoWeCollect": "2. Information We Collect",
          "privacyPolicy.infoWeCollectText": "We may collect and process the following types of personal data about you:",
          "privacyPolicy.personalInfo": "Personal Identification Information: Name, email address, phone number, company name, job title.",
          "privacyPolicy.technicalData": "Technical Data: Internet protocol (IP) address, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.",
          "privacyPolicy.usageData": "Usage Data: Information about how you use our website, products, and services.",
          "privacyPolicy.marketingData": "Marketing and Communications Data: Your preferences in receiving marketing from us and your communication preferences.",
          "privacyPolicy.howWeUse": "3. How We Use Your Information",
          "privacyPolicy.howWeUseText": "We use the information we collect in the following ways:",
          "privacyPolicy.provisionServices": "Provision of Services: To provide you with our services, including customer support.",
          "privacyPolicy.improveServices": "Improvement of Services: To understand how our services are used, and to improve them.",
          "privacyPolicy.marketing": "Marketing: To provide you with information, products, or services that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes.",
          "privacyPolicy.legalCompliance": "Legal Compliance: To comply with our legal obligations and regulatory requirements.",
          "privacyPolicy.legalBasis": "4. Legal Basis for Processing",
          "privacyPolicy.legalBasisText": "We process your personal data based on the following legal grounds:",
          "privacyPolicy.performanceContract": "Performance of a Contract: Where it is necessary for the performance of a contract to which you are a party.",
          "privacyPolicy.legitimateInterests": "Legitimate Interests: Where it is in our legitimate interest and it is not overridden by your rights.",
          "privacyPolicy.consent": "Consent: Where you have given us your consent to process your personal data for a specific purpose.",
          "privacyPolicy.legalObligation": "Legal Obligation: Where we need to comply with a legal obligation.",
          "privacyPolicy.sharingInfo": "5. Sharing Your Information",
          "privacyPolicy.sharingInfoText": "We may share your personal data with:",
          "privacyPolicy.serviceProviders": "Service Providers: Third parties who perform services on our behalf.",
          "privacyPolicy.professionalAdvisers": "Professional Advisers: Including lawyers, bankers, auditors, and insurers.",
          "privacyPolicy.complianceLaws": "Compliance with Laws: Government bodies and law enforcement agencies to comply with legal obligations.",
          "privacyPolicy.businessTransfers": "Business Transfers: Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets.",
          "privacyPolicy.dataSecurity": "6. Data Security",
          "privacyPolicy.dataSecurityText": "We have implemented appropriate security measures to prevent your personal data from being accidentally lost, used, accessed, altered, or disclosed in an unauthorized way. These measures include:",
          "privacyPolicy.encryption": "Encryption of data in transit and at rest.",
          "privacyPolicy.regularAudits": "Regular security audits.",
          "privacyPolicy.accessControls": "Secure access controls.",
          "privacyPolicy.dataRetention": "7. Data Retention",
          "privacyPolicy.dataRetentionText": "We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.",
          "privacyPolicy.yourRights": "8. Your Rights",
          "privacyPolicy.yourRightsText": "You have the following rights regarding your personal data:",
          "privacyPolicy.access": "Access: You can request access to your personal data.",
          "privacyPolicy.rectification": "Rectification: You can request correction of your personal data.",
          "privacyPolicy.erasure": "Erasure: You can request deletion of your personal data.",
          "privacyPolicy.restriction": "Restriction: You can request restriction of processing your personal data.",
          "privacyPolicy.dataPortability": "Data Portability: You can request transfer of your personal data.",
          "privacyPolicy.objection": "Objection: You can object to processing of your personal data.",
          "privacyPolicy.withdrawConsent": "Withdraw Consent: You can withdraw consent at any time where we are relying on consent to process your personal data.",
          "privacyPolicy.cookies": "9. Cookies",
          "privacyPolicy.cookiesText": "Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.",
          "privacyPolicy.thirdPartyLinks": "10. Third-Party Links",
          "privacyPolicy.thirdPartyLinksText": "Our website may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements.",
          "privacyPolicy.childrensPrivacy": "11. Children's Privacy",
          "privacyPolicy.childrensPrivacyText": "Our services are not intended for individuals under the age of 16. We do not knowingly collect personal data from children under 16.",
          "privacyPolicy.changesPolicy": "12. Changes to This Privacy Policy",
          "privacyPolicy.changesPolicyText": "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.",
          "privacyPolicy.contactUs": "13. Contact Us",
          "privacyPolicy.contactUsText": "If you have any questions about this Privacy Policy or our privacy practices, please contact us at:",
          "privacyPolicy.email": "Email: info@advetrees.com",
          "privacyPolicy.address": "Address: Advetrees Marketing, Kingsfordweg 151, Amsterdam, 1043 GR, Netherlands",  
        }
      },
      nl: {
        translation: {
          "navbar.home": "Thuis", // More formal than "Thuis"
          "navbar.vision": "Visie",
          "navbar.approach": "Aanpak", // "Benadering" is correct but "Aanpak" might be more natural for web copy
          "navbar.ecoInitiative": "Eco-initiatief",
          "navbar.process": "Werkwijze", // "Proces" is correct but "Werkwijze" might convey a more practical approach
          "navbar.letsConnect": "Laten we Contact Opnemen", // A bit more inviting and personal
          "navbar.bookACall": "Druk op <span class='shortcut-key'>B</span> voor een call",
          "Sales": "Verkopen",
          "Conversions": "Conversies", // Corrected spelling from 'Converions'
          "Leads": "Leads", // Often left in English to refer to potential customers in business contexts
          "Profits": "Winsten 💸",
          "Clicks": "Kliks",
          "ROI": "ROI", // "Rendement op Investering" could be used but ROI is commonly understood
          "marketing.boost": "Boost met Duurzame Marketing", // More formal and polished
          "clicks.trees": "Van Kliks naar Bomen - Laat je Merk en de Planeet Groeien 🌍", // Better phrasing
          "strategy.call": "Gratis Strategiegesprek Aanvragen", // More natural call-to-action
          "scroll.down": "Scroll omlaag...", // Simple and clear
          "vision.ourVision": "Onze Visie",
          "vision.header": "Strategieën op Maat, <span class='highlight'>Zekere</span> Resultaten",
          "vision.subText": "Wij zijn een digitaal marketingbureau in Amsterdam, gespecialiseerd in het ontwikkelen van gestroomlijnde strategieën voor lokale bedrijven. In tegenstelling tot grotere bureaus, waar klanten vaak verloren gaan in de massa, zorgen wij voor persoonlijke service met directe, transparante communicatie.",
          "vision.bookCall": "Boek Uw Gesprek Nu",
          "vision.scroll": "Scroll omlaag...",
          "approach.ourApproach": "Onze Aanpak",
          "approach.header": "<span class='highlight'>AI-Gedreven</span> Campagne Optimalisatie",
          "approach.altText": "Hersenen",
          "process.ourProcess": "Ons Proces",
          "process.header": "Samen <span class='highlight'>Succes</span> Creëren",
          "process.bookCall": "Boek Nu Uw Gesprek",
          "process.scroll": "Scroll omlaag...",
          "ecoInitiative.sectionTitle": "Eco-initiatief",
          "ecoInitiative.header": "Sluit je aan voor een <span class='highlight'>groenere toekomst</span>",
          "ecoInitiative.subText": "Elke klik plant bomen in cruciale ecologische gebieden, compenseert koolstofuitstoot van digitale advertenties en biedt transparante rapporten over de impact van elke boom. Laten we samen de planeet verzorgen, klik voor klik!",
          "footer.promotionPart1": "4 stappen om meer klanten met Meta Ads... ",
          "footer.promotionPart2": "(Voor Gratis)",
          'footer.subscribeButton': "Claim het nu",
          'footer.subscribedMessage': "Geabonneerd!",
          'footer.errorMessage': "Probeer het later opnieuw.",
          'footer.emailPlaceholder': "Vul je e-mailadres in",
          'footer.terms': "Door aan te melden bevestig je dat je instemt met onze Gebruiksvoorwaarden.",
          'footer.logoAltText': "Advetrees Logo",
          'footer.navigation': "Navigatie",
          'footer.followUs': "Volg ons",
          'footer.addressTitle': "Adres",
          'footer.addressDetail': "Kingsfordweg 151, Amsterdam, 1043 GR, Nederland",
          'footer.inquiryTitle': "Onderzoek",
          'footer.email': "info@advetrees.com",
          'footer.privacyPolicy': "Privacybeleid",
          'footer.copyright': "© 2024 Advetrees Marketing. Alle rechten voorbehouden.",
          "metaAdsLanding.title": "Wilt u uw bedrijf laten groeien met Meta Ads?",
          "metaAdsLanding.getGuide": "Haal uw gratis gids nu op en boost uw bedrijf vandaag nog!",
          "metaAdsLanding.boostBusiness": "Versterk uw bedrijf met Meta Ads!",
          "metaAdsLanding.subscribeButton": "Claim het nu",
          "metaAdsLanding.readerCount": "Sluit je aan bij 100+ lezers",
          'metaAdsLanding.emailPlaceholder': "Vul je e-mailadres in",
          'metaAdsLanding.subscribedMessage': "Geabonneerd!",
          'metaAdsLanding.errorMessage': "Probeer het later opnieuw.",
          "thankYou.title": "Bedankt!",
          "thankYou.subText": "Je gratis gids is onderweg en zou binnen enkele minuten in je mailbox moeten zijn. Als je het niet ziet, controleer dan je SPAM-map.",
          "calendly.title": "Geïnteresseerd in een gratis marketinganalyse?",
          "calendly.description": "Ontdek wat we voor uw bedrijf kunnen doen zonder verplichtingen of agressieve verkoopstrategieën. Kies een tijd in de agenda om met ons te praten. We waarderen uw tijd en inzet voor groei.",
          "privacyPolicy.title": "Privacybeleid",
          "privacyPolicy.lastUpdated": "Laatst bijgewerkt: [25 mei 2024]",
          "privacyPolicy.introduction": "1. Inleiding",
          "privacyPolicy.introductionText": "Welkom bij Advetrees Marketing (“wij”, “ons”, “onze”). Wij zijn toegewijd aan het beschermen en respecteren van uw privacy. Dit privacybeleid legt uit hoe wij uw informatie verzamelen, gebruiken, openbaar maken en beschermen wanneer u onze website [www.advetrees.com] bezoekt, onze diensten gebruikt of anderszins met ons communiceert.",
          "privacyPolicy.infoWeCollect": "2. Informatie die we verzamelen",
          "privacyPolicy.infoWeCollectText": "Wij kunnen de volgende soorten persoonsgegevens over u verzamelen en verwerken:",
          "privacyPolicy.personalInfo": "Persoonlijke identificatiegegevens: Naam, e-mailadres, telefoonnummer, bedrijfsnaam, functietitel.",
          "privacyPolicy.technicalData": "Technische gegevens: Internetprotocol (IP) adres, browsertype en -versie, tijdzone-instelling, browser plug-in types en versies, besturingssysteem en platform, en andere technologieën op de apparaten die u gebruikt om toegang te krijgen tot deze website.",
          "privacyPolicy.usageData": "Gebruiksgegevens: Informatie over hoe u onze website, producten en diensten gebruikt.",
          "privacyPolicy.marketingData": "Marketing- en communicatiegegevens: Uw voorkeuren in het ontvangen van marketing van ons en uw communicatievoorkeuren.",
          "privacyPolicy.howWeUse": "3. Hoe we uw informatie gebruiken",
          "privacyPolicy.howWeUseText": "Wij gebruiken de informatie die we verzamelen op de volgende manieren:",
          "privacyPolicy.provisionServices": "Verlening van diensten: Om u van onze diensten te voorzien, inclusief klantenondersteuning.",
          "privacyPolicy.improveServices": "Verbetering van diensten: Om te begrijpen hoe onze diensten worden gebruikt en om deze te verbeteren.",
          "privacyPolicy.marketing": "Marketing: Om u te voorzien van informatie, producten of diensten die u bij ons aanvraagt of die wij denken dat u interessant vindt, waar u toestemming heeft gegeven om voor dergelijke doeleinden te worden gecontacteerd.",
          "privacyPolicy.legalCompliance": "Naleving van de wet: Om te voldoen aan onze wettelijke verplichtingen en regelgevende vereisten.",
          "privacyPolicy.legalBasis": "4. Wettelijke basis voor verwerking",
          "privacyPolicy.legalBasisText": "Wij verwerken uw persoonsgegevens op basis van de volgende wettelijke gronden:",
          "privacyPolicy.performanceContract": "Uitvoering van een contract: Waar het nodig is voor de uitvoering van een contract waarbij u partij bent.",
          "privacyPolicy.legitimateInterests": "Legitieme belangen: Waar het in ons legitieme belang is en dit niet wordt overschaduwd door uw rechten.",
          "privacyPolicy.consent": "Toestemming: Waar u ons uw toestemming heeft gegeven om uw persoonsgegevens voor een specifiek doel te verwerken.",
          "privacyPolicy.legalObligation": "Wettelijke verplichting: Waar we moeten voldoen aan een wettelijke verplichting.",
          "privacyPolicy.sharingInfo": "5. Uw informatie delen",
          "privacyPolicy.sharingInfoText": "Wij kunnen uw persoonsgegevens delen met:",
          "privacyPolicy.serviceProviders": "Dienstverleners: Derde partijen die diensten voor ons uitvoeren.",
          "privacyPolicy.professionalAdvisers": "Professionele adviseurs: Inclusief advocaten, bankiers, auditors en verzekeraars.",
          "privacyPolicy.complianceLaws": "Naleving van de wet: Overheidsinstanties en wetshandhavingsinstanties om te voldoen aan wettelijke verplichtingen.",
          "privacyPolicy.businessTransfers": "Bedrijfsoverdrachten: Derde partijen aan wie wij delen van ons bedrijf of onze activa kunnen verkopen, overdragen of fuseren.",
          "privacyPolicy.dataSecurity": "6. Gegevensbeveiliging",
          "privacyPolicy.dataSecurityText": "Wij hebben passende beveiligingsmaatregelen geïmplementeerd om te voorkomen dat uw persoonsgegevens per ongeluk verloren gaan, gebruikt, geopend, gewijzigd of openbaar gemaakt worden op een ongeoorloofde manier. Deze maatregelen omvatten:",
          "privacyPolicy.encryption": "Encryptie van gegevens tijdens overdracht en opslag.",
          "privacyPolicy.regularAudits": "Regelmatige beveiligingsaudits.",
          "privacyPolicy.accessControls": "Veilige toegangscontroles.",
          "privacyPolicy.dataRetention": "7. Gegevensbewaring",
          "privacyPolicy.dataRetentionText": "Wij zullen uw persoonsgegevens alleen bewaren zolang als nodig is om de doeleinden te vervullen waarvoor wij deze hebben verzameld, inclusief voor de doeleinden van het voldoen aan wettelijke, boekhoudkundige of rapportagevereisten.",
          "privacyPolicy.yourRights": "8. Uw rechten",
          "privacyPolicy.yourRightsText": "U heeft de volgende rechten met betrekking tot uw persoonsgegevens:",
          "privacyPolicy.access": "Toegang: U kunt toegang tot uw persoonsgegevens aanvragen.",
          "privacyPolicy.rectification": "Rectificatie: U kunt correctie van uw persoonsgegevens aanvragen.",
          "privacyPolicy.erasure": "Wissing: U kunt verzoeken om verwijdering van uw persoonsgegevens.",
          "privacyPolicy.restriction": "Beperking: U kunt verzoeken om beperking van de verwerking van uw persoonsgegevens.",
          "privacyPolicy.dataPortability": "Gegevensoverdraagbaarheid: U kunt verzoeken om overdracht van uw persoonsgegevens.",
          "privacyPolicy.objection": "Bezwaar: U kunt bezwaar maken tegen de verwerking van uw persoonsgegevens.",
          "privacyPolicy.withdrawConsent": "Toestemming intrekken: U kunt uw toestemming te allen tijde intrekken waar wij vertrouwen op toestemming om uw persoonsgegevens te verwerken.",
          "privacyPolicy.cookies": "9. Cookies",
          "privacyPolicy.cookiesText": "Onze website maakt gebruik van cookies om u te onderscheiden van andere gebruikers van onze website. Dit helpt ons om u een goede ervaring te bieden wanneer u onze website bezoekt en stelt ons ook in staat onze site te verbeteren.",
          "privacyPolicy.thirdPartyLinks": "10. Links naar derden",
          "privacyPolicy.thirdPartyLinksText": "Onze website kan links naar websites, plug-ins en applicaties van derden bevatten. Door op die links te klikken of die verbindingen in te schakelen, kunnen derden gegevens over u verzamelen of delen. Wij controleren deze websites van derden niet en zijn niet verantwoordelijk voor hun privacyverklaringen.",
          "privacyPolicy.childrensPrivacy": "11. Privacy van kinderen",
          "privacyPolicy.childrensPrivacyText": "Onze diensten zijn niet bedoeld voor personen jonger dan 16 jaar. Wij verzamelen niet bewust persoonsgegevens van kinderen jonger dan 16 jaar.",
          "privacyPolicy.changesPolicy": "12. Wijzigingen in dit privacybeleid",
          "privacyPolicy.changesPolicyText": "Wij kunnen dit privacybeleid van tijd tot tijd bijwerken. Wij zullen u op de hoogte stellen van eventuele wijzigingen door het nieuwe privacybeleid op deze pagina te plaatsen. U wordt geadviseerd om dit privacybeleid periodiek te herzien op eventuele wijzigingen.",
          "privacyPolicy.contactUs": "13. Neem contact met ons op",
          "privacyPolicy.contactUsText": "Als u vragen heeft over dit privacybeleid of onze privacypraktijken, neem dan contact met ons op:",
          "privacyPolicy.email": "E-mail: info@advetrees.com",
          "privacyPolicy.address": "Adres: Advetrees Marketing, Kingsfordweg 151, Amsterdam, 1043 GR, Nederland",
        }
      }
    },
    lng: "nl",
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });
