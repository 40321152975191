import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from './CustomButton';
import TextPopup from './TextPopup';
import processImgEN from '../assets/img/ai_process.png';  // English Image
import processImgNL from '../assets/img/ai_process_nl.png';  // Dutch Image
import SectionHeadingCapsule from './SectionHeadingCapsule';
import { Container, Row, Col } from "react-bootstrap";

export const Process = () => {
    const { t, i18n } = useTranslation();
    const [isVisible, setVisible] = useState(false);
    const processImg = i18n.language === 'nl' ? processImgNL : processImgEN; // Dynamically select image based on language

    const handleScroll = () => {
        const section = document.getElementById('process');
        const screenPosition = window.innerHeight;
        const sectionTop = section.getBoundingClientRect().top;

        if (sectionTop < screenPosition) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section className="process-section common-background" id="process">
            <div className="container">
                <Row className="justify-content-center">
                    <Col xs={12}>
                        <SectionHeadingCapsule title={t('process.ourProcess')} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} md={8}>
                        <div className="content">
                            {isVisible && (
                                <>
                                    <TextPopup
                                        headerHtml={t('process.header')}
                                        className="fadeInUp"
                                    />
                                    <img src={processImg} alt={t('process.ourProcess')} className="img-fluid process-img fadeInUp" />
                                </>
                            )}
                            <CustomButton
                                text={t('process.bookCall')}
                            />
                            <div className="scroll-indicator mt-4">
                                <div className="scroll-indicator-dot"></div>
                                <div className="scroll-down-text">{t('process.scroll')}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Process;
