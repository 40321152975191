/* global fbq */
import React, { useState, useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MetaAdsNavbar from './MetaAdsNavbar';
import ebookImgEN from '../assets/img/ebook.png'; // Dutch Image
import ebookImgNL from '../assets/img/ebook_nl.png'; // Dutch Image
import stars from '../assets/img/stars.png';

export const MetaAdsLanding = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const ebook = i18n.language === 'nl' ? ebookImgNL : ebookImgEN; // Dynamically select image based on language

    useEffect(() => {
        setMessage(t('metaAdsLanding.subscribeButton'));
      }, [t, i18n.language]);    
    
    const trackLead = () => { // Define the trackLead function
        fbq('track', 'Lead');
        };
      
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            const formData = {
              email: email
            };
      
            const response = await fetch('https://mlek9h42g5.execute-api.eu-north-1.amazonaws.com/proxy', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(formData)
            });
      
            if (response.ok) {
              setEmail('');
              setMessage(t('metaAdsLanding.subscribedMessage')); // Update button text to "Subscribed!"
              setError('');
              trackLead();  // Track the lead when the form submission is successful
              
              setTimeout(() => {
                navigate(`/free-strategy-call?lang=${i18n.language}`);
            }, 1000);

            } else {
              setError(t('metaAdsLanding.errorMessage')); // Set custom error message
            }
        } catch (error) {
            setError(t('metaAdsLanding.errorMessage'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <MetaAdsNavbar />
            <section className="banner meta-ads-landing" id="meta-ads-landing">
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col md={6} className="text-center-new text-md-left mb-4 mb-md-0 order-2 order-md-1 banner-content">
                            <h1>{t('metaAdsLanding.title')}</h1>
                            <p>{t('metaAdsLanding.getGuide')}</p>
                            <p className="banner-text-small">{t('metaAdsLanding.boostBusiness')}</p>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formBasicEmail" className="input-group">
                                    <Form.Control type="email" placeholder={t('metaAdsLanding.emailPlaceholder')} value={email} onChange={e => setEmail(e.target.value)} required />
                                    <Button variant="primary" type="submit" disabled={loading}>
                                        {message}
                                    </Button>
                                </Form.Group>
                            </Form>
                            <div className="reader-count-container">
                                <span className="reader-count-text">{t('metaAdsLanding.readerCount')}</span>
                                <img src={stars} alt="Stars" className="reader-stars" />
                            </div>
                        </Col>
                        <Col md={6} className="text-center-new order-1 order-md-2">
                            <img src={ebook} alt="Meta Ads Illustration" className="img-fluid large-ebook-image banner-image" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default MetaAdsLanding;
