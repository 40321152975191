import React from 'react';
import CustomButton from './CustomButton';
import TextPopup from './TextPopup';
import ecoImgEN from '../assets/img/ecoclicks.png'; // English Image
import ecoImgNL from '../assets/img/ecoclicks_nl.png'; // Dutch Image
import { useTranslation } from 'react-i18next';
import SectionHeadingCapsule from './SectionHeadingCapsule';
import { Container, Row, Col } from "react-bootstrap";

export const EcoInitiative = () => {
    const { t, i18n } = useTranslation();
    const ecoImg = i18n.language === 'nl' ? ecoImgNL : ecoImgEN; // Dynamically select image based on language

    return (
        <section className="ecoInitiative-section common-background" id="eco-initiative">
            <div className="container">
                <Row className="justify-content-center">
                    <Col xs={12}>
                        <SectionHeadingCapsule title={t('ecoInitiative.sectionTitle')} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} md={8}>
                        <div className="content">
                            <TextPopup
                                headerHtml={t('ecoInitiative.header')}
                                subTextHtml={t('ecoInitiative.subText')}
                            />
                            <img src={ecoImg} alt="ecoImg" className="img-fluid ecoInitiative-img" />
                            {/* Scroll Indicator */}
                            {/* <div className="scroll-indicator mt-4">
                                <div className="scroll-indicator-dot"></div>
                                <div className="scroll-down-text">{t('scroll.down')}</div>
                            </div> */}
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default EcoInitiative;
