import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import logo from '../assets/img/advetrees_logo.png';
import flagEN from '../assets/img/flag_en.svg';
import flagNL from '../assets/img/flag_nl.svg';
import i18n from '../i18n';
import './CustomButton.css';

export const NavBar = () => {
    const { t, i18n } = useTranslation();
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleLanguageChange = (lang) => {
        console.log(`Changing language to ${lang}`);
        i18n.changeLanguage(lang);
    };

    const toggleLanguage = () => {
        const newLang = i18n.language === 'en' ? 'nl' : 'en';
        i18n.changeLanguage(newLang);
    };

    const handleShortcutKeyPress = (event) => {
        if (event.key.toLowerCase() === 'b' && !['INPUT', 'TEXTAREA', 'SELECT'].includes(document.activeElement.tagName)) {
            window.open('https://cal.com/advetrees/', '_blank');
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleShortcutKeyPress);
        return () => window.removeEventListener('keydown', handleShortcutKeyPress);
    }, []);

    return (
        <Navbar expand="md" className={`${scrolled ? "scrolled" : ""}`}>
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo} alt="Logo" />
                </Navbar.Brand>
                <div className="d-flex ms-auto align-items-center">
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>
                    <div className="d-md-none">
                        <button onClick={toggleLanguage} className="language-toggle-button">
                            <img src={i18n.language === 'en' ? flagNL : flagEN} alt="Toggle Language" />
                        </button>
                    </div>
                </div>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <div className="d-none d-md-block">
                            <Dropdown className="language-dropdown ms-3">
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="language-dropdown-toggle">
                                    <img src={i18n.language === 'nl' ? flagNL : flagEN} alt="flag" className="language-dropdown-img" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="language-dropdown-menu">
                                    <Dropdown.Item onClick={() => handleLanguageChange('nl')} className="language-dropdown-item">
                                        <img src={flagNL} alt="Nederlands" className="language-dropdown-img" /> Nederlands
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleLanguageChange('en')} className="language-dropdown-item">
                                        <img src={flagEN} alt="English" className="language-dropdown-img" /> English
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
