import React from 'react';

const SectionHeadingCapsule = ({ title }) => {
  return (
    <div className="section-heading-capsule">
      <h5>{title}</h5>
    </div>
  );
};

export default SectionHeadingCapsule;
