import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';

const CalendlyWidget = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="calendly-section">
            <div className="container my-5">
                <div className="row align-items-center">
                    <div className="col-md-6 text-white d-flex flex-column justify-content-center text-center">
                        <h2 style={{ fontSize: '2.5rem', color: '#c4ea2af3'}}>{t('calendly.title')}</h2>
                        <p>{t('calendly.description')}</p>
                    </div>
                    <div className="col-md-6">
                        <div className="calendly-inline-widget" 
                            data-url="https://cal.com/advetrees" 
                            style={{ width: '100%', height: '520px' }}> {/* Updated height here */}
                            {/* Calendly widget will be inserted here */}
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalendlyWidget;
