import React from 'react';
import CustomButton from './CustomButton';
import TextPopup from './TextPopup';
import { useTranslation } from 'react-i18next';
import SectionHeadingCapsule from './SectionHeadingCapsule';
import { Container, Row, Col } from "react-bootstrap";

export const Vision = () => {
    const { t } = useTranslation();

    return (
        <section className="vision-section common-background" id="vision">
            <div className="container">
                <Row className="justify-content-center">
                    <Col xs={12}>
                        <SectionHeadingCapsule title={t('vision.ourVision')} />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs={12} md={8}>
                        <div className="content">
                            <TextPopup 
                                headerHtml={t('vision.header')}
                                subTextHtml={t('vision.subText')}
                            />
                            <CustomButton
                                text={t('vision.bookCall')}
                            />
                            {/* Scroll Indicator */}
                            <div className="scroll-indicator mt-4">
                                <div className="scroll-indicator-dot"></div>
                                <div className="scroll-down-text">{t('vision.scroll')}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Vision;
