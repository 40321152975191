import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";

export const Banner = () => {
    const { t } = useTranslation();
    const [loopNum, setLoopNum] = useState(0);
    const [show, setShow] = useState(true);
    const toRotate = ["Sales", "Conversions", "Leads", "Profits", "ROI"].map(key => t(key)); // Translate here
    
    useEffect(() => {
        const changeText = () => {
            setShow(false);
            setTimeout(() => {
                setLoopNum(prevLoopNum => (prevLoopNum + 1) % toRotate.length);
                setShow(true);
            }, 500);
        };

        const ticker = setInterval(changeText, 1000);
        return () => clearInterval(ticker);
    }, [loopNum, t]); // Add 't' to dependencies to re-run effect when language changes

    return (
        <section className="banner common-background" id="home">
            <Container>
                <Row className="align-items-center justify-content-center">
                    <Col xs={12} md={6} xl={7} className="text-center">
                        <h1>{t("marketing.boost")}</h1>
                        <h1 className="txt-slide">
                            <span style={{ fontStyle: 'italic' }} className={`wrap ${show ? 'show' : ''}`}>
                                {toRotate[loopNum]}
                            </span>
                        </h1>
                        <p>{t("clicks.trees")}</p>
                        <CustomButton text={t("strategy.call")} href="https://cal.com/advetrees/"/>
                        {/* Scroll Indicator */}
                        <div className="scroll-indicator mt-4">
                            <div className="scroll-indicator-dot"></div>
                            <div className="scroll-down-text">{t("scroll.down")}</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};