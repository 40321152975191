import React from 'react';
import './CustomButton.css'; // Make sure to keep the CSS import
import rightArrowIcon from "../assets/img/right-arrow.png";

const CustomButton = ({ text, className }) => {
  const navigateToExternalLink = () => {
    window.open('https://cal.com/advetrees/', '_blank'); // Open in new tab
  }

  return (
    <button className={`custom-button ${className}`} onClick={navigateToExternalLink}>
      {text}
      <img src={rightArrowIcon} className="button-icon" alt="Icon" />
    </button>
  );
};

export default CustomButton;
